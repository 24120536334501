@mixin onDesktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin onDesign {
  @media (min-width: 1360px) {
    @content;
  }
}

