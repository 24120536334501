@import "../../utils/mixins";

.warning-popup {
    position: relative;
    display: flex;
 
    &__container {
       background-color:   #001528;
        position: absolute;
        transform: translate(-45%, -100%);
       
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 280px;
        height: 150px;
        border-right: 1px solid #37616c;
        border-radius: 20px;
        padding: 15px;
        box-shadow: 0 0 5px #00ffff;
        z-index: 10;

        @include onDesktop {
        transform: translate(-25%, -90%);
          }
    }

    &__button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
    }
    
    &__button {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        height: 30px;
        width: 90px;
        background: #44e1f2;
        border-radius: 10px;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        color: #001528;
        outline: none;
        border: 1px solid #44e1f2;
        @include onDesktop {
          width: 90px;
        }
      }

      &__button:hover {
        background: #001528;
        color: #44e1f2;
      }
}