@import "../../../utils//mixins";

.USDC-faucet {
  padding: 20px;
  @include onDesktop {
    padding: 80px;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 26px;
    text-align: center;
    margin-top: 20px;
    color: #44e1f2;

    @include onDesktop {
      margin-top: 0;
    }
  }

  &__block-rules {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
    padding: 20px 10px;
    margin-bottom: 12px;
    margin-top: 30px;
    border: 1.5px solid #37616c;
    border-radius: 25px;
    margin-bottom: 30px;

    @include onDesktop {
      margin-bottom: 50px;
      padding: 30px;
    }
  }

  &__slogan {
    font-size: 18px;
  }

  &__rules {
    list-style: circle;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 20px;
    font-size: 15px;
  }

  &__rules-item {
    word-break: break-all;
    margin-top: 10px;
  }

  &__Web3Button {
    display: flex;
    justify-content: center;
  }

  &__block-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 70px;
    background: #001528;
    padding: 30px;
    border-radius: 20px;

    @include onDesktop {
      padding: 40px;
      flex-direction: row;
    }
  }
  &__block-button .switch-chain {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__get-USDC_button {
    display: flex;
    justify-content: center;
    align-self: center;
  }

  &__block-FAQs {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__FAQs {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__FAQs-item {
    display: flex;
    flex-direction: column;
    widows: 100%;
    padding: 15px;
    background: #001528;
    border-radius: 10px;
    border: 1px solid #37616c;

    @include onDesktop {
    }
  }

  &__FAQs-question {
    margin-bottom: 15px;
  }

  &__FAQs-answer {
    font-size: 15px;
    line-height: 20px;

    @include onDesktop {
      font-size: 18px;
      line-height: 26px;
    }
  }

  &__FAQs-link {
    text-decoration: underline;
    margin-right: 5px;
    margin-left: 5px;
  }

  &__prevention-text {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 10px;

    @include onDesktop {
      font-size: 18px;
      line-height: 28px;
    }
  }
}
