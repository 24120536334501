.faqs {
    width: 100%;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
    font-weight: 200;
    font-size: 16px;
    letter-spacing: 1.2;
    line-height: 30px;
  }
  .fags-title {
    margin-left: 10px;
    margin-bottom: 10px;
  }
  
  .faqs .faq {
    margin: 15px;
    padding: 15px;
    background: #001528;
    border-radius: 10px;
    border: 1px solid #37616c;
  }
  
  .faqs .faq .faq-question {
    position: relative;
    font-size: 20px;
    line-height:24px;
    padding-right: 80px;
    transition: all 0.4s ease;
  }
  
  .faqs .faq .faq-question::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
  }
  
  .faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
  }
  
  .faqs .faq.open .faq-question {
    margin-bottom: 15px;
    margin-top: 10px;
  }
  
  .faqs .faq.open .faq-question::after {
    content: "-";
  }
  
  .faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
    font-size: 16px;
  }