@import "../../../utils//mixins";

.buyre-proposal {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: #001528;
  border-radius: 20px;
  padding: 15px;
  color: #f3fafb;
  width: 100%;

  @include onDesktop {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0;

    @include onDesktop {
      border-right: 1px solid #37616c;
      flex-wrap: wrap;
      margin-bottom: 0;
    }
  }

  &__list-completed-transactions {
    width: 100%;
    flex-wrap: wrap;
  }

  &__list-completed-transactions li {
    width: auto;
  }

  &__list-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include onDesktop {
      margin-bottom: 0;
    }
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 30%;
    flex-grow: 1;
    padding-left: 3px;
    padding-right: 3px;

    @include onDesktop {
      width: 25%;
      padding-left: 5px;
    }
  }

  &__lable {
    font-weight: 400;
    font-size: 13px;
    opacity: 0.6;
    @include onDesktop {
      display: flex;
    }
  }

  &__value {
    font-weight: 400;
    font-size: 16px;
  }
  &__value-date {
    font-size: 13px;
  }

  &__value-icon {
    position: relative;
    width: 25px;
    height: 25px;
    border: 1px solid #44e1f2;
    border-radius: 5px;
  }
  &__value-icon-tick {
    position: absolute;
    top: -15px;
    left: -7px;
  }

  &__value-amount {
    position: relative;
    padding-left: 25px;
  }

  &__value-amount::before {
    position: absolute;
    display: flex;
    content: "";
    background-image: url(../../../assets/images/USDT.svg);
    background-size: contain;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
  }

  &__value-discord {
    display: flex;
  }

  &__discord-logo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &__comment {
    display: flex;
    border-bottom: 1px solid #44e1f2;
    min-height: 30px;
    align-items: flex-end;
    font-size: 14px;
    font-weight: 100;
  }

  &__showLess {
    display: flex;
    background-color: initial;
    border: none;
    outline: none;
    padding: 0;
    font-weight: 400;
    font-size: 13px;
    color: #f3fafb;
    opacity: 0.6;
    cursor: pointer;
  }

  &__showLess:hover {
    background-color: initial;
    box-shadow: none;
  }

  &__hiden-desktop {
    @include onDesktop {
      display: none;
    }
  }

  &__list-item-blockButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    @include onDesktop {
      width: 50%;
      margin-right: 10px;
      gap: 5px;
    }
  }

  &__list-main {
    flex-wrap: wrap;
    @include onDesktop {
      flex-wrap: wrap;
      padding: 2px;
      width: 20%;
    }
  }

  &__list-main li {
    width: 30%;
    margin-bottom: 10px;

    @include onDesktop {
      margin-bottom: 0;
    }
  }

  &__list-main li:last-child {
    width: 100%;
    @include onDesktop {
      width: 100%;
    }
  }
  &__list-main  &__completed-transactions {
    @include onDesktop {
      width: 50%;
    }
  }

  &__list-other {
    width: auto;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 15px;
    @include onDesktop {
      width: 55%;
      padding: 2px;
    }
  }

  &__list-button {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-top: 1px solid #37616c;
    padding-top: 18px;

    @include onDesktop {
      flex-direction: column;
      border-top: none;
      padding-top: 0;
      width: 25%;
      padding: 2px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    height: 48px;
    width: 150px;
    background: #44e1f2;
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #001528;
    outline: none;
    border: 1px solid #44e1f2;
    @include onDesktop {
      width: 155px;
    }
  }

  &__button:hover {
    background: #001528;
    color: #44e1f2;
  }

  &__button-confirmFiaPayment {
    box-sizing: border-box;
    padding-right: 25px;
    width: 200px;
  }

  &__button-request-to-buy {
    box-sizing: border-box;
    padding-right: 25px;
  }

  &__button-confirmFiaPayment .fieldDescription__desktop::after {
    color: #000;
    border: 1px solid #000;
  }

  &__button-request-to-buy {
    width: 130px;
    height: 55px;
  }

  &__button-request-to-buy .fieldDescription__desktop::after {
    color: #000;
    border: 1px solid #000;
  }

  &__button-dispute {
    width: 130px;
    height: 50px;
    padding-right: 25px;
    border: none;
    background-color: #f17949;
    border: 1px solid #f17949;
    border-radius: 10px;

    @include onDesktop {
      width: 100px;
      height: 45px;
      margin-right: 15px;
    }
  }

  &__button-dispute:hover {
    border: 1px solid #44e1f2;
  }

  &__button-cancellation {
    width: 150px;
    height: 50px;
    padding-right: 25px;
    font-size: 14px;
    border: none;
    background-color: #f17949;
    border: 1px solid #f17949;

    @include onDesktop {
      margin-right: 25px;
    }
  }

  &__button-cancellation:hover {
    border: 1px solid #44e1f2;
  }

  &__button-check {
    width: 130px;
    background-color: initial;
    color: #44e1f2;
    height: 53px;
    border: 1px solid #37616c;
  }

  &__hiden-phone {
    display: none;
    @include onDesktop {
      display: flex;
    }
  }

  &__list-button-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 2px;
  }

  &__field {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include onDesktop {
      display: flex;
      flex-direction: column;
      width: 80%;
      margin-right: 10px;
    }
  }

  &__input {
    box-sizing: border-box;
    display: flex;
    margin: 0;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 20px;
    height: 55px;
    width: 160px;
    max-width: 100%;
    border: 1px solid #37616c;
    border-radius: 10px;
    background: initial;
    font-weight: 500;
    font-size: 18px;
    color: #f3fafb;

    @include onDesktop {
      width: 100%;
    }
  }

  &__input::placeholder {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #f3fafb;
    opacity: 0.6;
    white-space: break-spaces;
    position: relative;
    top: -25px;
    left: 0;
    @include onDesktop {
      top: -25px;
    }
  }

  &__label {
    position: relative;
    top: 25px;
    left: 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: #f3fafb;
    opacity: 0.6;

    @include onDesktop {
      position: absolute;
      top: 2px;
      left: 10px;
    }
  }

  &__check-text {
    position: relative;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  &__check-text .correct::before {
    position: absolute;
    content: "";
    display: block;
    margin-bottom: 10px;
    background-image: url(../../../assets/images/checkmark_tick_grin.svg);
    background-size: contain;
    width: 30px;
    height: 30px;
    top: -5px;
    right: -35px;
  }

  &__check-text .not-correct::before {
    position: absolute;
    content: "";
    display: block;
    margin-bottom: 10px;
    background-image: url(../../../assets/images/cross.svg);
    background-size: contain;
    width: 20px;
    height: 20px;
    top: 0;
    right: -25px;
  }

  &__text-disput-resolved {
    color: #f17949;
  }
}
