@import "../../../utils/mixins";

.dispute-resolution {
  display: flex;
  flex-direction: column;
  padding: 20px;
  @include onDesktop {
    margin-top: 50px;
    min-height: calc(100vh - 305px);
    padding: 0 90px;
  }

  &__disputes {
    margin-top: 30px;

    @include onDesktop {
      margin-top: 50px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.3px;
    color: #44e1f2;
    margin-bottom: 10px;

    @include onDesktop {
      margin-bottom: 20px;
    }
  }

  &__dispute-item {
    margin-bottom: 12px;

    @include onDesktop {
      margin-bottom: 20px;
    }
  }

  &__voite {
    @include onDesktop {
      display: flex;
      gap: 40px;
    }
  }

  &__rules-button {
    display: none;

    position: relative;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    width: 50%;
    height: 60px;
    padding: 20px;
    margin-bottom: 20px;
    background: #033859;
    border-radius: 10px;
    border: none;
    outline: none;
    font-weight: 200;
    font-size: 18px;
    line-height: 150%;
    color: #f3fafb;
    opacity: 0.6;
    letter-spacing: 1.2;

    @include onDesktop {
      display: flex;
    }
  }

  &__rules-button:hover {
    background: #032e49;
    outline: none;
    border: none;
    box-shadow: none;
  }

  &__rules-button::after {
    position: absolute;
    display: flex;
    content: "";
    background-image: url(../../../assets/images/down_button.svg);
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    right: 20%;
    top: 18px;
  }
}
