@import "../../utils/mixins";


.fieldDescription {
  &__phone {
    display: block;
    @include onDesktop {
      display: none;
    }
  }

  &__desktop {

    display: none;
    @include onDesktop {
      display: block;
    }
  }

  &__desktop::after {
   
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    content: "?";
    font-size: 12px;
    color: #44e1f2;
    border: 1px solid #44e1f2;
    margin-right: 5px;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    top: 5px;
    right: 0;
  }

  &__phone::after {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    content: "?";
    font-size: 16px;
    color: #44e1f2;
    border: 1px solid #44e1f2;
    margin-right: 5px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    top: 5px;
    right: 0;
  }

  &__item {
    position: absolute;
    background-color: #F3FAFB;
    color: #000;
    padding: 10px;
    top: 35px;
    right: 0;
    z-index: 210;
    border-radius: 10px;
    font-family: "Wix Madefor Display";
    font-size: 12px;
    font-weight: 100;
  }
}