@import "../../../utils/mixins";

.dispute-rules {
  @include onDesktop {
    display: none;
  }
  &__slider-container {
    position: relative;
  }

  &__slider {
    width: 330px;
    height: 56px;
    margin-bottom: 30px;
    background: #001528;
    border-radius: 30px;
    position: relative;
    overflow: visible;
  }

  &__slider-text-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    left: 0;
    right: 0;
    top: 5px;
  }

  &__slider-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }

  &__slider-range-sell {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 50px;
    background-color: transparent;
    outline: none;
    position: relative;
    z-index: 3;
    padding: 0;
  }

  &__slider-range-sell::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 170px;
    height: 50px;
    background-image: url(../../../assets/images/Buyer_and_Seller.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #669da3;
    border-radius: 30px;
    border: none;
    position: relative;
    left: 0;
    bottom: 0;
    z-index: 4;
  }

  &__slider-range-sell::-moz-range-thumb {
    width: 170px;
    height: 50px;
    background-image: url(../../../assets/images/Buyer_and_Seller.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #899192;
    border-radius: 30px;
    border: none;
    position: relative;
    left: 0;
    bottom: 0;
    z-index: 4;
  }
  &__slider-range-sell::-ms-thumb {
    width: 170px;
    height: 50px;
    background-image: url(../../../assets/images/Buyer_and_Seller.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #669da3;
    border-radius: 30px;
    border: none;
    position: relative;
    left: 0;
    bottom: 0;
    z-index: 4;
  }

  &__slider-range-buy {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 50px;
    background-color: transparent;
    outline: none;
    position: relative;
    z-index: 3;
    padding: 0;
  }

  &__slider-range-buy::-webkit-slider-thumb {
    width: 170px;
    height: 50px;
    background-image: url(../../../assets/images/For_token_holders.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #669da3;
    border-radius: 30px;
    border: none;
    position: relative;
    left: 0;
    bottom: 0;
    z-index: 4;
  }

  &__slider-range-buy::-moz-range-thumb {
    width: 170px;
    height: 50px;
    background-image: url(../../../assets/images/For_token_holders.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #669da3;
    border-radius: 30px;
    border: none;
    position: relative;
    left: 0;
    bottom: 0;
    z-index: 4;
  }
  &__slider-range-buy::-ms-thumb {
    width: 170px;
    height: 50px;
    background-image: url(../../../assets/images/For_token_holders.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #669da3;
    border-radius: 30px;
    border: none;
    position: relative;
    left: 0;
    bottom: 0;
    z-index: 4;
  }

  &__rules-item-buy {
    display: none;
  }

  &__rules-item {
    display: flex;
    flex-direction: column;
  }

  &__rules-item-sell {
    display: none;
  }

  &__rules-rule {
    display: flex;
    align-items: flex-start;
    gap: 23px;
    width: 100%;
    margin-bottom: 23px;
  }

  &__rule-number {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 41px;
    height: 41px;
    background: #001528;
    border: 1px solid #001528;
    border-radius: 100px;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    color: #44e1f2;
  }

  &__rule-text {
    display: block;
    max-width: 260px;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
    color: #f3fafb;
  }

  &__rule-text a {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
    color: #f3fafb;
    text-decoration: underline;
  }
}
