@import "../../../utils/mixins";

.prooals {
  padding: 20px;

  @include onDesktop {
    min-height: calc(100vh - 280px);
    padding: 20px 90px;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.3px;
    color: #44e1f2;
    margin-bottom: 10px;
    margin-top: 80px;

    @include onDesktop {
      margin-top: 55px;
    }
  }

  &__title-text {
    margin-bottom: 10px;
    font-weight: 100;
    font-size: 16px;
    line-height: 150%;
    color: #f3fafb;

    @include onDesktop {
      position: relative;
      display: flex;
      width: auto;
      margin-bottom: 20px;
    }
  }

  &__title-text::after {
    @include onDesktop {
      position: absolute;
      display: block;
      content: "";
      width: 45%;
      height: 0.5px;
      background-color: #4da9b3;
      top: 30px;
    }
  }

  &__proposal-item {
    margin-bottom: 12px;
    @include onDesktop {
      margin-bottom: 20px;
    }
  }

  &__sort {
    position: absolute;
    right: 0;
    top: 50px;
    width: 75%;
    z-index: 10;

    @include onDesktop {
      width: 40%;
      top: 0;
      min-width: 300px;
    }
  }
}

.react-tabs__tab-list {
  justify-content: flex-start;
}

.react-tabs__tab-list .react-tabs__tab {
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.3px;
  color: #44e1f272;
  outline: none;
  border: none;
}

.react-tabs__tab-list .react-tabs__tab--selected {
  background: initial;
  font-size: 20px;
  border-radius: 5px 5px 0 0;
  border: none;
  border-top: 1px solid #c6c6c6;
  border-left: 1px solid #c6c6c6;
  border-right: 1px solid #c6c6c6;
  border-bottom: 3px solid #000;
  color: #44e1f2;
}

.react-tabs__tab-list .react-tabs__tab:focus:after {
  display: none;
}

.react-tabs__tab-panel {
  padding-top: 30px;
}
