@import "../../../utils/mixins";

.prooals {
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.3px;
    color: #44e1f2;
  }

  &__proposal-item {
    margin-bottom: 12px;
    @include onDesktop {
      margin-bottom: 20px;
    }
  }
}

.react-tabs__tab-list {
  justify-content: flex-start;
}

.react-tabs__tab-list .react-tabs__tab {
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.3px;
  color: #44e1f272;
  outline: none;
  border: none;
}

.react-tabs__tab-list .react-tabs__tab--selected {
  background: initial;
  font-size: 20px;
  border-radius: 5px 5px 0 0;
  border: none;
  border-top: 1px solid #c6c6c6;
  border-left: 1px solid #c6c6c6;
  border-right: 1px solid #c6c6c6;
  border-bottom: 3px solid #000;
  color: #44e1f2;
}

.react-tabs__tab-list .react-tabs__tab:focus:after {
  display: none;
}

.react-tabs__tab-panel {
  padding-top: 30px;
}
