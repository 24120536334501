@import "../src/utils/mixins";

@font-face {
  font-family: "Wix Madefor Display";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/WixMadeforDisplayRegular.otf");
}

body {
  margin: 0 !important;
  padding: 0;
  font-family: "Wix Madefor Display", -apple-system, BlinkMacSystemFont,
    "Wix Madefor Display", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
li,
ul,
div,
h1,
h2,
h3,
h4,
section,
span,
p {
  margin: 0;
  padding: 0;
}

a {
  color: #fff;
}

.container {
  background-color: #000;
  color: #fff;
  font-family: "Wix Madefor Display";
  margin: 0;
  padding: 0;
}

ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: center;
}

li a {
  text-decoration: none;
}

li a:hover {
  text-decoration: underline;
}

button:hover {
  background-color: #00ffff;
}

button:disabled {
  background-color: #999;
  border: 1px solid #999;
  cursor: not-allowed;
}

button:focus {
  outline: none;
}

.notification {
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
}

.notification.success {
  background-color: #006600;
  color: #fff;
}

.notification.error {
  background-color: #ff0000;
  color: #fff;
}

.web3-modal {
  z-index: 20;
}


