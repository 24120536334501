@import "../../../utils//mixins";

.get-USDC-button {
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 220px;
    background: #44e1f2;
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #001528;
    outline: none;
    border: 1px solid #44e1f2;

    @include onDesktop {
        width:400px;
      }

    &:hover {
        background: #001528;
        color: #44e1f2;

    }
}