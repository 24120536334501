@import "../../utils/mixins";

.chat {
  position: fixed;
  right: -260px;
  bottom: -340px;
  height: 400px;
  width: 320px;
  z-index: 10;

  @include onDesktop {
    width: 400px;
    bottom: -300px;
    right: -320px;
  }

  &__open-chat {
    background: initial;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__open-chat:hover {
    border: none;
    outline: none;
    background: initial;
  }

  &__open-chat-img {
    background-color: initial;
    width: 40px;

    @include onDesktop {
      width: 40px;
    }
  }

  &__block-open-chat {
    display: flex;
    position: relative;
  }

  &__totalcount {
    display: flex;
    width: 10px;
    height: 10px;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 50%;
    position: relative;
    background: #00ffff;
    color: #000;
    padding: 6px;
    top: -15px;
    left: -35px;

    @include onDesktop {
      padding: 10px;
      top: -30px;
      left: -50px;
    }
  }

  &__container {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: -105%;
    right: 280px;
    border-radius: 20px;
    box-shadow: 0 0 4px #00ffff;
    background: #001528;
    padding: 8px;

    @include onDesktop {
      padding: 10px;
      right: 350px;
      top: -109%;
    }
  }

  &__nav-bar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 30%;
    background: #001528;
    overflow: visible;
    padding: 10px 5px;
  }

  &__search {
    margin-bottom: 20px;
  }

  &__adress {
    color: #fff;
    background: initial;
    border: none;
    outline: none;
    cursor: pointer;
    padding-top: 10px;
    font-size: 12px;

    @include onDesktop {
      font-size: 15px;
    }
  }

  &__adress:hover {
    background: initial;
    color: #000;
    position: relative;
    background: #fff;
  }

  &__adress-active {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    background: #fff;
    color: #000;
  }

  &__adress-query {
    color: #00ffff;
  }

  &__block-address-count {
    display: flex;
    width: 5px;
    height: 5px;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 50%;
    position: relative;
    background: #00ffff;
    color: #000;
    padding: 7px;
    top: -25px;
    left: -15px;

    @include onDesktop {
      width: 5px;
      height: 5px;
      padding: 8px;
      top: -28px;
    }
  }

  &__block-content {
    box-sizing: border-box;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    height: 100%;
    padding: 5px;
    color: #000;
  }

  &__block-content-hiden {
    background: #ffffff;
    width: 70%;
  }

  &__block-messagesses-container {
    overflow-y: scroll;
    padding: 10px;
    padding-top: 5px;
  }

  &__block-messagesses {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 10px;
    cursor: pointer;
  }

  &__caveat {
    font-size: 12px;

    color: #001528;
    margin-bottom: 5px;
    border-bottom: 1px solid #001528;
  }

  &__load-older-messages {
    background: initial;
    border: none;
    outline: none;
    color: #959595;
  }

  &__load-older-messages:hover {
    background: initial;
    text-decoration: underline;
    color: #000;
  }

  &__block-content-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__block-content-item-my {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__message {
    display: flex;
    width: 100%;
    align-self: flex-end;
    color: #001528;
    text-align: left;
    padding: 5px;
    margin-top: 5px;
  }

  &__message-my {
    display: block;
    color: #001528;
    text-align: right;
    background: #44e1f2;
    border-radius: 20px;
    width: auto;
  }

  &__time-message {
    font-size: 10px;
    text-align: left;
    margin-bottom: 10px;
    color: #b3b3b3;
  }

  &__time-message-my {
    font-size: 10px;
    text-align: right;
    margin-bottom: 10px;
  }

  &__block-sendMessage {
    width: 100%;
    display: flex;
  }

  &__inpute-message {
    width: 100%;
    border-radius: 10px;
    margin-right: 5px;
    border: 1px solid #001528;
  }

  &__send {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    height: 38px;
    width: 100px;
    background: #44e1f2;
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #001528;
    outline: none;
    border: 1px solid #44e1f2;

  }

  &__send:hover {
    background-color: initial;
    color: #44e1f2;
    border: 1px solid #44e1f2;
  }
}
