@import "../../utils/mixins";

.footer {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 20px;
  background: #001528;
  width: 100%;

  @include onDesktop {
    padding: 20px 90px;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__container-top {
    display: flex;
    flex-direction: column;
    @include onDesktop {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__container-bottom {
    display: flex;
    flex-direction: row;
    gap: 70px;
    margin-top: 30px;
    margin-left: 10px;

    @include onDesktop {
      margin-left: 0;

    }
  }

  &__container-bottom a {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #f3fafb;
    text-decoration: none;
  }

  &__container-bottom a:hover {
    text-decoration: underline;
  }

  &__menu-container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include onDesktop {
      flex-direction: row;
      justify-content: space-between;
      gap: 50px;
    }
  }

  

  &__item {
    display: flex;
    flex-direction: row-reverse;
    align-self: flex-start;
    gap: 75px;
    margin-left: 10px;
    @include onDesktop {
      flex-direction: row;
      gap: 50px;
    }
  }

  &__about {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-bottom: 30px;
    @include onDesktop {
      flex-direction: column;
      gap: 15px;
      margin-bottom: 0;
    }
  }

  &__logo-img {
    width: 70px;
  }

  &__text {
    font-weight: 200;
    font-size: 16px;
    color: #f3fafb;

  }



  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    list-style: none;
    gap: 30px;
  }

  &__menu-item a {
    font-size: 16px;
    line-height: 20px;
    color: #f3fafb;
    opacity: 0.8;
  }

  &__link-button {
    background-color: initial;
    border: none;
    outline: none;
    padding: 0;
  }

  &__link-button:hover {
    background-color: initial;
  }

  &__menu-social {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include onDesktop {
      flex-direction: row;
      align-items: flex-start;
      gap: 35px;
    }
  }

  &__menu-social-img {
    width: 36px;
  }

  &__line-for-phone {
    display: block;
    margin: 0 auto;
    margin-top: 40px;
    height: 5px;
    width: 135px;
    background-color: #fff;
    border-radius: 10px;

    @include onDesktop {
      display: none;
     
    }

  }
}
