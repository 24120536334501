@import "../../utils/mixins";

.award-popup {
  position: fixed;
  right: 50%;
  top: 20%;
  transform: translateX(50%) translateY(50%);
  z-index: 20;
  margin: 0 auto;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: #001528;
    box-shadow: 0 0 2px #00ffff;
    border-radius: 20px;
    color: #000;
    max-width: 80%;
    min-height: 200px;

    @include onDesktop {
      flex-direction: row;
      gap: 100px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  &__title {
    color: #00e6e6;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    @include onDesktop {
      font-size: 18px;
    }
  }

  &__finja {
    width: 100px;
    @include onDesktop {
      width: 100px;
    }
  }
}
