@import "../../../utils/mixins";

.dispute {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #001528;
  border-radius: 20px;
  padding: 15px;
  color: #f3fafb;
  width: 100%;

  @include onDesktop {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    width: 100%;

    @include onDesktop {
      border-right: 1px solid #37616c;
      flex-wrap: wrap;
    }
  }

  &__list-block {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__list-block li {
    @include onDesktop {
      width: 33%;
      margin-bottom: 5px;
    }
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;

    flex-grow: 1;
    padding-left: 3px;
    padding-right: 3px;

    @include onDesktop {
      padding: 2px;
      width: auto;
    }
  }

  &__list-item-discord {
    align-items: center;
  }

  &__lable {
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    opacity: 0.6;
    @include onDesktop {
      display: block;
    }
  }

  &__value-main {
    font-weight: 500;
    font-size: 18px;

    @include onDesktop {
      font-size: 24px;
    }
  }

  &__value {
    font-weight: 400;
    font-size: 16px;
  }

  &__value-img {
    width: 30px;
    height: 30px;
  }

  &__showLess {
    display: flex;
    background-color: initial;
    border: none;
    outline: none;
    padding: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #fbf4f3;
    opacity: 0.6;
    cursor: pointer;
  }

  &__showLess:hover {
    background-color: initial;
    box-shadow: none;
  }

  &__hiden-desktop {
    @include onDesktop {
      display: none;
    }
  }

  &__list-button {
    display: flex;
    gap: 12px;
    border-top: 1px solid #376c3b;
    padding-top: 18px;

    @include onDesktop {
      flex-direction: column;
      border-top: none;
      padding-top: 0;
      padding-left: 10px;
      width: 20%;
      padding: 2px;
    }
  }

  &__list-main {
    display: flex;
    flex-direction: column;
    @include onDesktop {
      padding: 2px;
      width: 25%;
    }
  }

  &__list-main-item {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    @include onDesktop {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__list-other {
    @include onDesktop {
      padding: 2px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 100%;
    background: #44e1f2;
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #001528;
    outline: none;
    border: 1px solid #44e1f2;
    @include onDesktop {
      width: 165px;
    }
  }

  &__button:hover {
    background: #001528;
    color: #44e1f2;
  }

  &__button-dispute {
    width: 100px;
    height: 30px;
    border: none;
    background-color: #f17949;
    border-radius: 5px;
    color: #000;
  }

  &__button-dispute:hover {
    background-color: #f17949;
    box-shadow: 0 0 5px #e4682f;
    color: #fff;
  }

  &__button-resolve-dispute {
    position: relative;
    padding-right: 25px;
  }

  &__hiden-phone {
    display: none;
    @include onDesktop {
      display: flex;
    }
  }
}
