@import "../../utils/mixins";

.token-governance {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 30px 20px;
  padding-bottom: 40px;

  @include onDesktop {
    margin: 50px 100px;
  }

  &__title {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #f3fafb;

    @include onDesktop {
      font-size: 30px;
      margin-bottom: 40px;
    }
  }

  &__rules-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 100%;
    background: #001528;
    border-radius: 20px;
    margin-bottom: 20px;

    @include onDesktop {
      padding: 30px;
      font-size: 24px;
      margin-bottom: 50px;
    }
  }

  &__rules-title {
    font-size: 16px;
    margin-bottom: 25px;
    font-weight: 300;

    @include onDesktop {
      font-size: 24px;
    }
  }

  &__rules {
    box-sizing: border-box;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: flex-start;
    @include onDesktop {
      box-sizing: border-box;
    }
  }

  &__rules-item {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    gap: 20px;
    margin-bottom: 23px;
  }

  &__rule-number {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 30px;
    height: 30px;
    background: #002034;
    border: 1px solid #002034;
    border-radius: 100px;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    color: #44e1f2;

    @include onDesktop {
      width: 40px;
      height: 40px;
      font-size: 24px;
    }
  }

  &__rule-text {
    display: flex;
    max-width: 250px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    color: #f3fafb;

    @include onDesktop {
      box-sizing: border-box;
      max-width: 100%;
      align-self: center;
      font-size: 20px;
    }
  }

  &__link {
    color: #fff;
    text-decoration: underline;
  }

  &__infornations-container {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include onDesktop {
      flex-direction: row;
    }
  }

  &__infornations {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 5px;
    padding: 13px 10px;
    margin-bottom: 12px;
    border: 1.5px solid #37616c;
    border-radius: 20px;

    @include onDesktop {
      padding: 30px;
    }
  }

  &__infornations-title {
    font-size: 20px;
    margin-bottom: 25px;
    font-weight: 300;

    @include onDesktop {
      font-size: 24px;
    }
  }

  &__infornations-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 25px;
    font-weight: 300;

    @include onDesktop {
      font-weight: 200;
      font-size: 20px;
      line-height: 30px;
    }
  }
}
