@import "../../../utils/mixins";

.another-cryptocurrency {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;

  @include onDesktop {
    display: flex;
    box-sizing: border-box;
    margin-bottom: 0;
  }

  &__button {
    position: relative;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    width: 100%;
    height: 60px;
    padding: 5px;
    padding-right: 35px;
    margin-bottom: 20px;
    background: #033859;
    border-radius: 10px;
    border: none;
    outline: none;
    font-weight: 200;
    font-size: 14px;
    line-height: 150%;
    color: #f3fafb;
    opacity: 0.6;
    letter-spacing: 1.2;

    @include onDesktop {
      width: 50%;
      display: flex;
      font-size: 18px;
    }
  }

  &__button:hover {
    background: #032e49;
    outline: none;
    border: none;
    box-shadow: none;
  }

  &__button::after {
    position: absolute;
    display: flex;
    content: "";
    background-image: url(../../../assets/images/down_button.svg);
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    right: 5px;
    top: 18px;


    @include onDesktop {
      right: 20%;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-size: 26px;
    line-height: 32px;
    display: flex;
    justify-content: center;
    color: #ccd2d2;

    @include onDesktop {
      font-size: 34px;
      line-height: 40px;
    }
  }

  &__rules {
    display: flex;
    box-sizing: border-box;
    gap: 20px;
    width: 100%;

    @include onDesktop {
      margin-bottom: 30px;
    }
  }

  &__rules-item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 20px;
    background: #001528;
    border-radius: 20px;

    @include onDesktop {
      padding: 30px;
    }
  }

  &__block-logo {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;

    @include onDesktop {
      gap: 20px;
      margin-bottom: 40px;
    }
  }

  &__logo {
    width: 120px;
    height: 120px;
    background-color: #f3fafb;
    border-radius: 10px;
  }

  &__logo-img {
    width: 120px;
  }

  &__logo-img-uniswap {
    width: 80px;
    height: 60px;
  }

  &__rule-text {
    vertical-align: middle;
    font-style: normal;
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 18px;
    color: #bec2c3;

    @include onDesktop {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      font-size: 22px;
    }
  }
}
