@import "../../utils/mixins";

.switch-chain {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;

  @include onDesktop {
    margin-right: 80px;
  }

  &__select-network {
    display: flex;
    align-items: center;
    gap: 5px;
    background: initial;
    border: none;
    color: #fff;
    font-size: 14px;
    cursor: pointer;

    @include onDesktop {
      font-size: 18px;
      gap: 10px;
    }
  }

  &__select-network:hover {
    background: initial;
    text-decoration: underline;
  }

  &__select-network-logo {
    width: 30px;
    height: 30px;
  }

  &__block-networks {
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    gap: 10px;
    top: 55px;
    transform: translateX(30px);
    z-index: 10;
    background-color: #002034;
    padding: 10px;
    box-shadow: 0 0 4px #00ffff;
    border-radius: 10px;

    @include onDesktop {
      min-width: 250px;
      gap: 20px;
    }
  }

  &__network {
    display: flex;
    align-items: center;
    gap: 20px;
    background: initial;
    border: none;
    color: #fff;
    font-size: 15px;
    cursor: pointer;

    @include onDesktop {
      font-size: 18px;
    }
  }

  &__network:hover {
    display: flex;
    align-items: center;
    background: initial;
    border: none;
    color: #fff;
    font-size: 18px;
    text-decoration: underline;
  }

  &__network-logo {
    width: 30px;
    height: 30px;
  }
}
