@import "../../utils/mixins";

.please-connect {
  box-sizing: border-box;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 150px);
  z-index: 20;
  left: 0;
  top: 100px;

  backdrop-filter: blur(10px);
  @include onDesktop {
    height: calc(100vh - 270px);
    top: 100px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 70%;
    width: 60%;
    padding: 20px;
    background-color: #001528;
    box-shadow: 0 0 5px #00ffff;
    border-radius: 20px;
    color: #000;
    backdrop-filter: blur(10px);

    @include onDesktop {
      flex-direction: row;
      // gap: 60px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @include onDesktop {
      gap: 50px;
    }
  }

  &__title {
    color: #00e6e6;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    @include onDesktop {
      font-size: 30px;
    }
  }

  &__finja {
    width: 100px;
    @include onDesktop {
      width: 200px;
    }
  }

  &__text {
    font-weight: 100;
    color: #00e6e6;
    font-size: 14px;
    line-height: 24px;
  }

  &__metaMask {
    height: 20px;
    background-color: #00e6e6;
    padding: 2px 5px;
    margin-bottom: -5px;
    margin-left: 10px;
    border-radius: 5px;
  }
}
