@import "../../../utils/mixins";

.proposal {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #001528;
  border-radius: 20px;
  padding: 15px;
  color: #f3fafb;
  width: 100%;

  @include onDesktop {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0;

    @include onDesktop {
      border-right: 1px solid #37616c;
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  &__list-completed-transactions {
    width: 100%;
    flex-wrap: wrap;
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 30%;
    flex-grow: 1;
    padding-left: 3px;
    padding-right: 3px;

    @include onDesktop {
      padding: 2px;
      width: auto;
    }
  }

  &__list-item-container-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include onDesktop {
      justify-content: start;
    }
  }

  &__lable {
    font-weight: 400;
    font-size: 13px;
    opacity: 0.6;

    @include onDesktop {
      display: block;
    }
  }

  &__value {
    position: relative;
    font-weight: 400;
    font-size: 16px;
  }

  &__value-amount {
    padding-left: 25px;
  }

  &__value-amount::before {
    position: absolute;
    display: flex;
    content: "";
    background-image: url(../../../assets/images/USDT.svg);
    background-size: contain;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
  }

  &__value-icon {
    position: relative;
    width: 25px;
    height: 25px;
    border: 1px solid #44e1f2;
    border-radius: 5px;
  }

  &__value-icon-tick {
    position: absolute;
    top: -15px;
    left: -7px;
  }

  &__showLess {
    display: flex;
    background-color: initial;
    border: none;
    outline: none;
    padding: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #f3fafb;
    opacity: 0.6;
    cursor: pointer;
  }

  &__showLess:hover {
    background-color: initial;
    box-shadow: none;
  }

  &__hiden-desktop {
    @include onDesktop {
      display: none;
    }
  }

  &__list-main {
    flex-wrap: wrap;
    @include onDesktop {
      padding: 2px;
      width: 20%;
    }
  }

  &__list-main li {
    width: 30%;

  }

  &__list-main li:last-child {
    width: 100%;
    @include onDesktop {
      width: 100%;
    }
  }

  &__list-other {
    width: auto;
    flex-wrap: wrap;

    @include onDesktop {
      flex-direction: column;
      width: 55%;
      padding: 2px;
    }
  }

  &__list-other-item li {
    width: 33%;
    padding-bottom: 10px;
    @include onDesktop {
      padding-bottom: 0;
    }

  }

  &__list-button {
    display: flex;
    gap: 12px;
    border-top: 1px solid #37616c;
    padding-top: 18px;

    @include onDesktop {
      flex-direction: column;
      border-top: none;
      padding-top: 0;
      padding-left: 10px;
      width: 20%;
      padding: 2px;
    }
  }

  &__button-container {
    position: absolute;
  }

  &__button {
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 100%;
    background: #44e1f2;
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #001528;
    outline: none;
    border: 1px solid #44e1f2;

    @include onDesktop {
      width: 170px;
    }
  }

 
  &__button:hover {
    background: #001528;
    color: #44e1f2;
  }

  &__button-accept-request {
    padding-right: 25px;
  }

  &__button-accept-request > .fieldDescription__desktop::after {
    color: #000;
    border: 1px solid #000;
  }

  &__button-release-crypto {
    padding-right: 25px;
  }

  &__button-release-crypto .fieldDescription__desktop::after {
    color: #000;
    border: 1px solid #000;
  }

  &__button-dispute {
    width: 130px;
    height: 50px;
    padding-right: 25px;
    border: none;
    background-color: #f17949;
    border: 1px solid #f17949;
    border-radius: 10px;

    @include onDesktop {
      width: 100px;
      height: 45px;
      margin-right: 15px;
    }
  }

  &__button-dispute:hover {
    border: 1px solid #44e1f2;
  }

  &__button-cancellation {
    width: 150px;
    height: 50px;
    padding-right: 25px;
    font-size: 14px;
    border: none;
    background-color: #f17949;
    border: 1px solid #f17949;

    @include onDesktop {
      width: 200px;
      height: 45px;
      font-size: 16px;
    }
  }

  &__button-cancellation:hover {
    border: 1px solid #44e1f2;
  }

  &__hiden-phone {
    display: none;
    @include onDesktop {
      display: flex;
    }
  }

  &__text-disput-resolved {
    color: #f17949;
    max-width: 300px;
  }

  &__list-no-active-request {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__line-phone {
    display: block;
    height: 1px;
    width: 100%;
    background-color: #37616c;

    @include onDesktop {
      display: none;
    }
  }
}
