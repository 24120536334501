@import "../../../utils/mixins";

.dispute-filters {
  display: flex;
  flex-direction: column;
  background: #002034;
  border-radius: 20px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;

    @include onDesktop {
      flex-direction: row;
    }
  }

  &__list-item {
    position: relative;
    border: 1px solid #37616c;
    border-radius: 10px;

    @include onDesktop {
      min-width: 24%;
    }
  }

  &__label {
    position: relative;
    top: 5px;
    left: 10px;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: #f3fafb;
    opacity: 0.6;

    @include onDesktop {
      position: absolute;
      top: 5px;
    }
  }

  &__input {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 60px;
    padding: 25px 25px 5px 10px;
    background: #002034;
    border-bottom: 1px solid #37616c;
    border-radius: 10px;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    margin-top: -22px;
    color: #f3fafb;
    opacity: 0.6;
    cursor: pointer;

    @include onDesktop {
      margin-top: 0;
    }
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 60px;
    padding: 5px 20px 5px 10px;
    background: #002034;
    border-radius: 10px;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #f3fafb;
    opacity: 0.6;
    cursor: pointer;
  }

  &__button:disabled {
    background: #002034;
    cursor: not-allowed;
  }

  &__button-active {
    align-items: flex-start;
    flex-direction: column;
  }

  &__button:hover {
    background-color: #031927;
  }

  &__button::after {
    position: absolute;
    display: block;
    content: "";
    background-image: url(../../../assets/images/down_button.svg);
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    right: 10px;
    top: 20px;
  }

  &__button:disabled::after {
    display: none;
  }

  &__loader {
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    right: 10px;
    top: 20px;
    z-index: 10;
  }

  &__button-open::after {
    position: absolute;
    display: block;
    content: "";
    background-image: url(../../../assets/images/up_button.svg);
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    right: 10px;
    top: 20px;
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #f3fafb;
    opacity: 1;
  }

  &__names-list {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 10px;
    border-radius: 20px;
  }

  &__names-list-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    padding: 5px 20px 5px 10px;
    margin-bottom: 5px;
    background: #002034;
    border: 1px solid #37616c;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #f3fafb;
    opacity: 0.6;
    cursor: pointer;
  }

  &__names-list-item:hover {
    background-color: #031927;
  }

  &__showLess {
    display: flex;

    background-color: initial;
    border: none;
    outline: none;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #f3fafb;
    opacity: 0.6;
    cursor: pointer;
  }

  &__showLess:hover {
    background-color: initial;
    box-shadow: none;
  }
}
