@import "../../../utils/mixins";

.home-rules-desk {
  display: none;

  @include onDesktop {
    display: flex;
    box-sizing: border-box;
    width: 97%;
  }

  &__title {
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    display: flex;
    align-items: center;
    color: #f3fafb;
  }

  &__rules {
    display: flex;
    box-sizing: border-box;
    gap: 20px;
    width: 100%;
  }

  &__rules-item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    padding: 20px;
    background: #001528;
    border-radius: 20px;
  }

  &__rules-item-sell {
    display: none;
  }

  &__rules-rule {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
    margin-bottom: 23px;
  }

  &__rule-number {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 41px;
    height: 41px;
    background: #002034;
    border: 1px solid #002034;
    border-radius: 100px;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    color: #44e1f2;
  }

  &__rule-text {
    display: block;
    max-width: 410px;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
    color: #f3fafb;
  }
}
