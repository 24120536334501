@import "../../utils/mixins";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Wix Madefor Display";
  width: 100%;
  background-color: #000;
 
  &__phone {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    @include onDesktop {
      display: none;
    }
  }

  &__logo {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;

    @include onDesktop {
      margin-top: 10px;
    }
  }

  &__logo-img {
    width: 50px;
    z-index: 100;
  }

  &__logo-img-polygon {
    position: absolute;
    width: 130px;
    top: 45px;
    left: -10px;
    // z-index: -1;

    @include onDesktop {
      width: 170px;
      top: 30px;
      // left: -50px;
    
    }
  }

  &__menu-button {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 30px;
    border: none;
    outline: none;
    background-color: initial;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  &__menu-button:hover {
    background-color: initial;
    box-shadow: none;
  }

  &__menu-button-item {
    display: block;
    height: 1.5px;
    width: 30px;
    height: 1.5px;
    background-color: #44e1f2;
    z-index: 220;
  }

  &__menu-button-item-active-top {
    transform: rotate(45deg) translateX(6px);
  }

  &__menu-button-item-active-bottom {
    transform: rotate(-45deg) translateX(6px);
  }

  &__menu-phone {
    box-sizing: border-box;
    position: absolute;
    width: 200px;
    min-height: 450px;
    right: 0;
    top: 0;
    background: #001528;
    border-radius: 20px 0px 0px 20px;
    z-index: 215;
  }

  &__menu-phone-list {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 450px;
    margin-top: 100px;
    margin-bottom: 25px;
    margin-left: 30px;
  }

  &__menu-phone-list-cripto {
    display: flex;
    flex-direction: column;
  }

  &__menu-phone-list-cripto-container {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
  }

  &__menu-phone-link-cripto a {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
  }

  &__menu-phone-list-other {
    display: flex;
    flex-direction: column;
  }

  &__menu-phone-list-social {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
  }

  &__menu-phone-link a {
    color: #f3fafb;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  &__menu-phone-link {
    margin-bottom: 30px;
  }

  &__menu-phone-link-button {
    background-color: initial;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    text-align: start;
  }

  &__menu-phone-link-button:hover {
    background-color: inherit;
  }

  &__menu-phone-img-discord {
    background-size: contain;
    width: 36px;
    height: 28px;
  }

  &__desktop {
    display: none;

    @include onDesktop {
      display: flex;
      padding: 0 90px;
    }
  }

  &__menu-desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: #010007;
  }

  &__menu-desktop-item {
    gap: 40px;
  }

  &__menu-desktop-link {
    display: flex;
    align-items: center;
    margin: 0;
  }

  &__menu-desktop-link a {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #f3fafb;
    opacity: 0.8;
  }
}
