@import "../../../utils/mixins";

.title {
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  letter-spacing: 0.3px;
  color: #44e1f2;
  margin-top: 20px;
  margin-bottom: 20px;
}

.createProposal {
  display: flex;
  flex-direction: column;
  padding: 20px 12px 20px 12px;
  margin-bottom: 50px;
  background: #002034;
  border-radius: 20px;
  color: #f3fafb;

  @include onDesktop {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    padding-bottom: 30px;
    margin-bottom: 40px;
  }

  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.3px;
    color: #f3fafb;

    @include onDesktop {
      margin-bottom: 20px;
    }
  }

  &__field-list {
    display: flex;
    flex-direction: column;

    @include onDesktop {
      flex-direction: row;
    }
  }

  &__field {
    position: relative;
    border: 1px solid #37616c;
    border-radius: 10px;
    margin-bottom: 10px;
    min-height: 60px;

    @include onDesktop {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  &__field-amount {
    @include onDesktop {
      width: 60%;
    }
  }

  &__field-price {
    @include onDesktop {
      width: 60%;
    }
  }

  &__input {
    box-sizing: border-box;
    display: flex;
    margin: 0;
    padding-top: 30px;
    padding-left: 10px;
    width: 100%;
    max-width: 100%;
    border: none;
    outline: none;
    border-radius: 10px;
    background: #002034;
    font-weight: 500;
    font-size: 18px;
    color: #f3fafb;
  }

  &__input {
    -moz-appearance: textfield;
  }

  &__input::-webkit-inner-spin-button,
  &__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
  }

  &__label {
    position: absolute;
    top: 5px;
    left: 10px;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: #f3fafb;
    opacity: 0.6;

    @include onDesktop {
      position: absolute;
      top: 5px;
    }
  }

  &__label-nowrap {
    white-space: nowrap;
  }

  &__payment-method {
    position: relative;
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
  }

  &__payment-method-button {
    position: absolute;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-right: 30px;
    font-size: 14px;
    color: #f3fafb;
    opacity: 0.6;
    text-align: left;
    background: #002034;
    border: none;
    outline: none;
  }

  &__input-amount {
    @include onDesktop {
      width: 120px;
      flex-grow: 1;
    }
  }

  &__input-price {
    @include onDesktop {
      width: 120px;
      flex-grow: 1;
    }
  }

  &__input-select {
    @include onDesktop {
      width: 210px;
      flex-grow: 1;
      margin-right: 10px;
    }
  }

  &__payment-method-button:hover {
    background: #002034;
    box-shadow: none;
  }

  &__payment-method-button::after {
    position: absolute;
    display: flex;
    content: "";
    background-image: url(../../../assets/images/down_button.svg);
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    right: 15px;
    top: 10px;
  }

  &__payment-method-button-active::after {
    position: absolute;
    display: flex;
    content: "";
    background-image: url(../../../assets/images/up_button.svg);
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    right: 15px;
    top: 10px;
  }

  &__payment-method-dropdown {
    box-sizing: content-box;
    position: absolute;
    background: #011929;
    top: 60px;
    width: 100%;
    z-index: 200;
    border-radius: 10px;
    padding: 2px;
  }

  &__dropdown {
    position: relative;
  }

  &__dropdown-item {
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    margin: 0;
    color: #f3fafb;
    opacity: 0.6;
    font-size: 14px;
    text-align: left;
    font-weight: 100;
    background-color: #002034;
    border: 1px solid #37616c;
    border-radius: 10px;
  }

  &__dropdown-item:hover {
    background-color: #011929;
    box-shadow: none;
  }

  &_checkbox {
    accent-color: #00e2fb;
    height: 15px; /* not needed */
    width: 15px; /* not needed */
    margin-right: 10px;
  }

  &__payment-method-description::after {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    content: "?";
    font-size: 14px;
    color: #00e2fb;
    border: 1px solid #00e2fb;
    margin-right: 5px;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    top: 10px;
    right: 0;
  }

  &__payment-method-description-item {
    position: absolute;
    background-color: #fff;
    color: #000;
    width: 50%;
    padding: 10px;
    top: 35px;
    right: 0;
    z-index: 210;
    border-radius: 10px;
    font-family: "Wix Madefor Display";
    font-size: 12px;
    font-weight: 100;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 60px;
    padding-right: 10px;
    background: #44e1f2;
    border-radius: 10px;
    font-weight: 700;
    font-size: 20px;
    color: #001528;
    border: none;
    outline: none;
  }

  &__button:hover {
    background: initial;
    color: #44e1f2;
  }

  &__error {
    display: flex;
    position: absolute;
    color: #ea6a15;
    width: 200px;
    bottom: -22px;

    font-weight: 100;
  }
}
