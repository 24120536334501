.payment-methods {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  &__metod {
    box-sizing: border-box;
    border: 1px solid #44e1f2;
    border-radius: 3px;
    padding: 2px 7px;
    font-size: 14px;
  }

  &__button {
    border: none;
    outline: none;
    background-color: initial;
    color: #44e1f2;
  }

  &__button:hover {
    background-color: initial;
  }
}
