@import "../../../utils/mixins";

.dispute-vote {
  display: flex;
  flex-direction: column;
  padding: 20px 12px 20px 12px;
  margin-bottom: 50px;
  background: #002034;
  border-radius: 20px;
  color: #f3fafb;
  @include onDesktop {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    padding-bottom: 30px;
    margin-bottom: 40px;
  }

  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.3px;
    color: #f3fafb;
    @include onDesktop {
      margin-bottom: 20px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;

    @include onDesktop {
      flex-direction: row;
      width: 100%;
      box-sizing: border-box;

      align-items: center;
    }
  }

  &__container-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;

    @include onDesktop {
      flex-direction: row;
      width: 100%;
      flex-grow: 3;
      margin-bottom: 0;
    }
  }

  &__container-item:last-child {
    margin-bottom: 0;
    @include onDesktop {
      width: 30%;
    }
  }

  &__field {
    position: relative;
    @include onDesktop {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: 10px;
    }
  }

  &__input {
    box-sizing: border-box;
    display: flex;
    margin: 0;
    padding-top: 30px;
    padding-left: 10px;
    height: 60px;
    width: 100%;
    max-width: 100%;
    border: 1px solid #37616c;
    border-radius: 10px;
    background: #002034;
    font-weight: 500;
    font-size: 18px;
    color: #f3fafb;
  }

  &__input {
    -moz-appearance: textfield;
  }

  &__input::-webkit-inner-spin-button,
  &__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
  }

  &__label {
    position: relative;
    top: 25px;
    left: 10px;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: #f3fafb;
    opacity: 0.6;

    @include onDesktop {
      position: absolute;
      top: 5px;
    }
  }

  &__payment-method {
    position: relative;
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
  }

  &__payment-method-button {
    position: absolute;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    margin-top: 5px;
    top: 10px;
    font-size: 14px;
    color: #f3fafb;
    opacity: 0.6;
    text-align: left;
    background: #002034;
    border: none;
    outline: none;
  }

  &__input-amount {
    @include onDesktop {
      width: 120px;
      flex-grow: 1;
    }
  }

  &__input-price {
    @include onDesktop {
      width: 120px;
      flex-grow: 1;
    }
  }

  &__input-select {
    @include onDesktop {
      width: 210px;
      flex-grow: 1;
      margin-right: 10px;
    }
  }

  &__input-descripion {
    @include onDesktop {
      width: 250px;
      flex-grow: 3;
    }
  }

  &__payment-method-button:hover {
    background: #002034;
    box-shadow: none;
  }

  &__payment-method-button::after {
    position: absolute;
    display: flex;
    content: "";
    background-image: url(../../../assets/images/down_button.svg);
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    right: 25px;
    top: 0;
  }

  &__payment-method-dropdown {
    box-sizing: content-box;
    position: absolute;
    background-color: #002034;
    top: 60px;
    width: 100%;
    z-index: 200;
    border-radius: 10px;
    padding: 2px;
  }

  &__dropdown {
    position: relative;
  }

  &__dropdown-item {
    height: 40px;
    width: 100%;
    margin: 0;
    font-size: 14px;
    color: #f3fafb;
    opacity: 0.6;
    text-align: left;
    font-weight: 100;
    background-color: #002034;
    border: 1px solid #37616c;
    border-radius: 10px;
  }

  &__dropdown-item:hover {
    background-color: #011929;
    box-shadow: none;
  }

  &__payment-method-description::after {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    content: "?";
    font-size: 14px;
    color: #000;
    background-color: #fff;
    margin-right: 5px;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    top: 10px;
    right: 0;
  }

  &__payment-method-description-item {
    position: absolute;
    background-color: #fff;
    color: #000;
    width: 50%;
    padding: 10px;
    top: 35px;
    right: 0;
    z-index: 210;
    border-radius: 10px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 60px;
    background: #44e1f2;
    border-radius: 10px;
    font-weight: 700;
    font-size: 20px;
    color: #001528;

    @include onDesktop {
      width: 160px;
    }
  }

  &__text {
    position: absolute;
    top: 80px;
    color: brown;
    white-space: nowrap;

    @include onDesktop {
      top: 60px;
      width: 100%;
      margin-top: 5px;
    }
  }
}
