@import "../../../utils/mixins";

.home {
  font-family: "Wix Madefor Display";
  font-style: normal;

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 80px);;
    background-image: url(../../../assets/images/Finja_Key.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 20px 20px 20px;

    @include onDesktop {
      box-sizing: border-box;
      height: calc(100vh - 80px);
      background-image: url(../../../assets/images/background_desktop_main.jpg);
      padding: 230px 90px 20px 90px;
      padding-top: calc(100vh / 4);
    }
  }

  &__title {
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    max-width: 270px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    color: #f3fafb;


    @include onDesktop {
      max-width: 835px;
      font-size: 70px;
      line-height: 80px;
    }
  }

  &__tagline {
    font-size: 18px;
    margin-top: 10px;
    vertical-align: middle;
    margin-bottom: 30px;
    margin-top: 30px;
    @include onDesktop {
    font-weight: 100;
    max-width: 800px;
    font-size:24px;
    letter-spacing: 0.9px;
    margin-top: 40px;
    margin-bottom: 0;
    }
  }

  &__usdc {
   margin-bottom: -3px;
    margin-left: 5px;
    margin-right: 2px;
    width: 20px;
    height: 20px;
  }

  &__social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 28px;

    @include onDesktop {
      gap: 35px;
    }
  }
  &__about {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 60px;
    @include onDesktop {
      padding: 46px 90px 46px 130px;
    }
  }

  &__advantage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;

    @include onDesktop {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__finja {
    margin-bottom: 40px;
    @include onDesktop {
      margin-bottom: 0;
      margin-right: 40px;
    }
  }

  &__finja-img {
    width: 215px;
    height: 270px;
  }

  &__advantage {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include onDesktop {
      margin-right: 15px;
      height: 100%;
    }
  }

  &__advantage-item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    flex-grow: 1;
    gap: 5px;
    padding: 13px 10px;
    margin-bottom: 12px;
    border: 1.5px solid #37616c;
    border-radius: 20px;

    @include onDesktop {
      margin-right: 20px;
      height: 200px;
    }

    @include onDesign {
      height: 160px;
      gap: 10px;
      padding: 10px 15px;
    }
  }

  &__advantage-title {
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    color: #f3fafb;
    margin-bottom: 10px;
  }

  &__advantage-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    height: 100%;
  }

  &__discord {
    display: flex;
    align-items: center;
    gap: 40px;
    border: 1.5px solid #37616c;
    border-radius: 20px;
    padding: 15px 20px 15px 35px;
    margin-top: 50px;

    @include onDesktop {
      box-sizing: border-box;
      width: 97%;
      padding: 35px;
    }
  }

  &__discord-text {
    max-width: 210px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #f3fafb;

    @include onDesktop {
      max-width: 370px;
    }
  }

  &__discord-link {
    margin-left: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #f3fafb;
  }

  &__FAQ {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
    margin-right: 10px;
  }
}
